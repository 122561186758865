import React from "react";
import { Container, Screen } from "./Landing";
import { Heading } from "../components/Heading";
import styled from "styled-components";
import { Button } from "../components/Button";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

export const StyledLogo = styled.img`
	width: 300px;
	padding-top: 50px;
	@media (min-width: 767px) {
		width: 400px;
	}
	transition: width 0.5s;
	transition: height 0.5s;
`;

//Text defined here cause this is p tag here instead of span
export const Text = styled.p`
	font-size: 1.6rem;
	color: white;
	margin-top: 25px;
	line-height: 40px;
`;

const TransparencyManifesto = () => {
	const navigate = useNavigate();

	return (
		<Screen>
			<Container flex={1} ai={"center"} style={{ backgroundColor: "var(--primary)" }}>
				<StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
				<div style={{ width: "60%", margin: " 50px auto" }}>
					<Heading>HYPEE GENESIS COLLECTION TRANSPARENCY MANIFESTO</Heading>
					<Heading>Powered by OVRT</Heading>
					<Text>
						Communities need to push the boundaries on what it means to be strategic about transparency.
					</Text>
					<Text>
						There's a correlation between community trust and team transparency. An adequate and timely degree of transparency has to be provided in the entire minting process in order to promote fair and equitable treatment for potential holders. We believe the team's business should be conducted in full view, in ways accessible to everyone, whether during a mint or not.
					</Text>
					<Text>
						By leaning into openness, communities can create space for full transparency and trust in a rapidly changing landscape. 
					</Text>
					<Text>
						Below are the measures we are taking to ensure a fair minting process for the OVRT x HYPEE Genesis Collection:
					</Text>
					<Text>
						<ul>
							<li>All team members will participate in the allowlist and minting process of the NFT along with community members</li>
							<li>HYPEES will be hidden when minted and will follow a “batched reveal” process. For additional information regarding the reveal process, check out the <a href="https://discord.gg/GapjS7EY6q">💥-official-updates</a> channel in the Discord as well as our <a href="https://docs.ovrt.xyz/">documentation page</a></li>
							<li>
								200 NFTs will be reserved by the OVRT Team:
								<ul style={{ marginLeft: "50px", fontSize: "1.4rem" }}>
									<li>28 NFTs will be reserved for core team and contributors of the project:</li>
									<li>30 NFTs will be reserved for guest artists that have collaborated for this project:</li>
									<li>142 NFTs will be distributed for marketing purposes</li>
								</ul>
							</li>
							<li>HYPEE artwork will be inspected for quality purposes by only the core team members</li>
							<li>Metadata of attributes and rarities will be randomized</li>
						</ul>
					</Text>
				</div>
				<Button onClick={() => navigate("/")}>Go Back</Button>
				<Footer hideTransparencyManifesto />
			</Container>
		</Screen>
	);
};

export default TransparencyManifesto;

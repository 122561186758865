import styled from "styled-components";

export const Heading = styled.h1`
	font-size: 2.2rem;
	color: white;
	text-align: center;
	@media (max-width: 767px) {
		font-size: 1.75rem;
	}
	font-weight: 900;
	margin-bottom: 20px;
`;

export const Text = styled.span`
	font-size: 1.6rem;
	color: white;
`;

export const SmallText = styled.span`
	font-size: 1.2rem;
	color: white;
`;

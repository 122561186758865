// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";

import CONFIG from '../../config/config.json';
import abi from '../../config/abi.json';


const fetchContractDataRequested = () => {
  return {
    type: "CONTRACT_REQUESTED",
  };
};

const fetchContractDataSuccess = (payload) => {
  return {
    type: "CONTRACT_SUCCESS",
    payload: payload,
  };
};

const fetchContractDataFailed = (payload) => {
  return {
    type: "CONTRACT_FAIL",
    payload: payload,
  };
};


const callDispatcher = async (contract, dispatch) => {
	const methods = contract.methods;
	
	const contractState = await methods.contractState().call();
	dispatch(fetchContractDataSuccess({ ...contractState, contractLoadedSuccess: true }));
}

const fetchContract = () => {

	return async (dispatch) => {
		dispatch(fetchContractDataRequested());
		try {
		  const { ethereum } = window;
		  if(!ethereum) {
			console.log('Unable to find eth, Is metamask installed?');
			dispatch(fetchContractDataFailed('METAMASK_NOT_FOUND'));
			return;
		  }

		  Web3EthContract.setProvider(ethereum);
		  new Web3(ethereum);
		  const SmartContractObj = new Web3EthContract(
			abi,
			CONFIG.CONTRACT_ADDRESS
		  );
		  
		  await callDispatcher(SmartContractObj, dispatch);
		  
	
		} catch(e) {
		  console.log(e);
		  dispatch(fetchContractDataFailed('Something went wrong while connecting to smart contract'));
		}
	  }
}

export default fetchContract;
import React from "react";
import { useSelector } from "react-redux";
import ComingSoon from "../components/ComingSoon";
import Action from "./Action";
import Spinner from "../components/Spinner";
import ErrorMessage from "../components/ErrorMessage";

const STATES = {
	CONTRACT_LOADED_BUT_MINTING_INACTIVE: 1,
	CONTRACT_LOADED_MINTING_ACTIVE_BUT_ACCOUNT_NOT_LINKED: 2,
	CONTRACT_LOADED_AND_ACCOUNT_LINKED_ONLY_PRESALE_ACTIVE_USER_WHITELISTED: 3,
	CONTRACT_LOADED_AND_ACCOUNT_LINKED_ONLY_PRESALE_ACTIVE_USER_NOT_WHITELISTED: 4,
	CONTRACT_LOADED_AND_ACCOUNT_LINKED_PUBLICSALE_ACTIVE: 5,
	CONTRACT_NOT_LOADED_BUT_ENV_ACTIVE: 6,
	CONTRACT_NOT_LOADED_ENV_INACTIVE: 7,
	CONTRACT_REQUESTED: 8,
	CONTRACT_ERROR: 9,
	ACCOUNT_ERROR: 10,
};

function whatIsEnabled(blockchain, contractData) {
	if (contractData.loading) {
		return STATES.CONTRACT_REQUESTED;
	}

	if (blockchain.errorMsg) {
		return STATES.ACCOUNT_ERROR;
	}

	if (contractData.contractLoadedSuccess) {
		if (!contractData.isActive) {
			return STATES.CONTRACT_LOADED_BUT_MINTING_INACTIVE;
		}

		if (!blockchain.account) {
			return STATES.CONTRACT_LOADED_MINTING_ACTIVE_BUT_ACCOUNT_NOT_LINKED;
		} else {
			if (!contractData.isPublicSaleActive && contractData.isPresaleActive) {
				if (blockchain.isWhitelisted) {
					return STATES.CONTRACT_LOADED_AND_ACCOUNT_LINKED_ONLY_PRESALE_ACTIVE_USER_WHITELISTED;
				} else {
					return STATES.CONTRACT_LOADED_AND_ACCOUNT_LINKED_ONLY_PRESALE_ACTIVE_USER_NOT_WHITELISTED;
				}
			}

			if (contractData.isPublicSaleActive) {
				return STATES.CONTRACT_LOADED_AND_ACCOUNT_LINKED_PUBLICSALE_ACTIVE;
			}
		}
	} else {
		if (contractData.errorMsg) {
			return STATES.CONTRACT_ERROR;
		}

		if (process.env.REACT_APP_IS_ACTIVE === "inactive") {
			return STATES.CONTRACT_NOT_LOADED_ENV_INACTIVE;
		}
		return STATES.CONTRACT_NOT_LOADED_BUT_ENV_ACTIVE;
	}
	return STATES.CONTRACT_NOT_LOADED_ENV_INACTIVE;
}

const Main = () => {
	const { contractData, blockchain } = useSelector((state) => state);

	console.log(whatIsEnabled(blockchain, contractData));
	switch (whatIsEnabled(blockchain, contractData)) {
		case STATES.CONTRACT_LOADED_BUT_MINTING_INACTIVE:
			return <Action isActive />;
		case STATES.CONTRACT_LOADED_MINTING_ACTIVE_BUT_ACCOUNT_NOT_LINKED:
			if (contractData.isPresaleActive) {
				return <Action showSupplyMessage showConnectButton presaleActive />;
			} else {
				return <Action showSupplyMessage showConnectButton publicSaleActive />;
			}

		case STATES.CONTRACT_LOADED_AND_ACCOUNT_LINKED_ONLY_PRESALE_ACTIVE_USER_WHITELISTED:
			return <Action showSupplyMessage showMintingCounter presaleActive />;
		case STATES.CONTRACT_LOADED_AND_ACCOUNT_LINKED_ONLY_PRESALE_ACTIVE_USER_NOT_WHITELISTED:
			return <Action presaleActive nonWhitelistedUser />;
		case STATES.CONTRACT_LOADED_AND_ACCOUNT_LINKED_PUBLICSALE_ACTIVE:
			return <Action showSupplyMessage showMintingCounter publicSaleActive />;
		case STATES.CONTRACT_NOT_LOADED_BUT_ENV_ACTIVE:
			return <Action showConnectButton />;
		case STATES.CONTRACT_NOT_LOADED_ENV_INACTIVE:
			return <Action />;
		case STATES.CONTRACT_REQUESTED:
			return <Spinner />;
		case STATES.CONTRACT_ERROR:
			console.log("Issue connecting to the contract: ", contractData.errorMsg);
			return <Action showConnectButton contractError />;
		case STATES.ACCOUNT_ERROR:
			console.log("Account Error: ", blockchain.errorMsg);
			return <Action errorMessage />;
		default:
			return <ComingSoon />;
	}
};

export default Main;

import axios from 'axios';


const PUBLIC_SALE_DATE = new Date('2022-03-27').getTime();

const ENDPOINT = "https://5nw3wjvfkh.execute-api.us-east-1.amazonaws.com/prod/is-registered"


export function isWhitelisted(address) {
	const url = `${ENDPOINT}?address=${address}`;
	return axios.get(url).then( response => {
		return response.data.registered;
	})
}



export function validForPresale(isWhitelisted) {
	return new Date().getTime() < PUBLIC_SALE_DATE && isWhitelisted;
}

export function isPublicSaleActive() {
	return new Date().getTime() > PUBLIC_SALE_DATE;
}
import React from "react";
import { ReactComponent as DiscordLogo } from "../assets/discord.svg";
import { ReactComponent as InstagramLogo } from "../assets/instagram.svg";
import { ReactComponent as TwitterLogo } from "../assets/twitter.svg";
import { ReactComponent as EtherscanLogo } from "../assets/etherscan.svg";
import { ReactComponent as OpenseaLogo } from "../assets/opensea.svg";
import "../assets/styles/footer.css";
import CONFIG from "../config/config.json";
import { SmallText } from "./Heading";
import { Link } from "react-router-dom";
import poweredByOvrt from "../assets/footer_powered_ovrt.png";

const Footer = ({ hideTransparencyManifesto }) => {
	return (
		<div className='footer'>
			<div className='social-logos'>
				<a href='https://discord.gg/Q7sB6rwAGE'>
					<DiscordLogo className='social' height='50px' color='white' />
				</a>
				<a href='https://www.instagram.com/_hypees/'>
					<InstagramLogo className='social' height='50px' color='white' />
				</a>
				<a href='https://twitter.com/_hypees'>
					<TwitterLogo className='social' height='50px' color='white' />
				</a>
				<a href={CONFIG.SCAN_LINK}>
					<EtherscanLogo className='social' height='50px' color='white' />
				</a>
				<a href={CONFIG.MARKETPLACE_LINK}>
					<OpenseaLogo className='social' height='50px' color='white' />
				</a>
			</div>
			{!hideTransparencyManifesto && (
				<div className='footer-links'>
					<Link to='/transparency-manifesto'>
						<SmallText className='link' style={{ color: "var(--secondary)" }}>
							Transparency Manifesto
						</SmallText>
					</Link>
				</div>
			)}
			<div className='footer-links'>
				<a href='https://ovrt.xyz/'>
					<img src={poweredByOvrt} width='400px' />
				</a>
			</div>
		</div>
	);
};

export default Footer;

import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button } from "../components/Button";
import Mint from "../components/Mint";
import { connect } from "../redux/blockchain/blockchainActions";
import { SmallText, Text } from "../components/Heading";
import ComingSoon from "../components/ComingSoon";
import Web3 from "web3";
import ErrorMessage from "../components/ErrorMessage";

const MESSAGES = {
	TOTAL_SUPPLY_LEFT: (totalLeft, totalSet) => `${totalLeft} / ${totalSet} HYPEES minted so far`,
	MINT_PRICE_MESSAGE: (weiAmount) => `Mint Price: ${Web3.utils.fromWei(weiAmount, "ether")} ETH per HYPEE`,
	SELECT_MINT_NUMBER: () => `Please select the number of HYPEES you would like to mint`,
	USER_NOT_WHITELISTED: () => `This wallet address does not qualify for presale.`,
	MOBILE_VIEW: () => `This Minting Application has been optimized for larger screens. 
	For optimal experience, please access this application from a desktop/laptop.`,
	METAMASK_ERROR: () => `Trouble connecting to metamask. Make sure it is installed.`,
};

const Action = ({ isActive, showConnectButton, showMintingCounter, contractError, errorMessage, presaleActive, publicSaleActive, nonWhitelistedUser }) => {
	const state = useSelector((state) => state);

	const dispatch = useDispatch();

	const handleConnectClick = (e) => {
		e.preventDefault();
		dispatch(connect());
	};

	const displayArray = [];

	//Welcome Message, shown always
	displayArray.push(
		<div key='public_sale_mint_box'>
			<ComingSoon key='welcome_message' isActive={isActive} presaleActive={presaleActive} publicSaleActive={publicSaleActive} counterVisible={showMintingCounter} />
			<br />
		</div>
	);

	//If Minting counter is shown, show the minting happening, price, quantity message
	if (showMintingCounter) {
		displayArray.push(
			<div key='mint_box'>
				{publicSaleActive ? <Mint key='public_sale_mint_box' /> : <Mint presaleActive key='presale_mint_box' />}
				<br />
			</div>
		);
	}

	//If user not whitelisted, show the error msg
	if (nonWhitelistedUser) {
		displayArray.push(<Text key='user_not_whitelisted_msg'>{MESSAGES.USER_NOT_WHITELISTED()}</Text>);
	}

	//Show the connect button
	if (showConnectButton) {
		displayArray.push(
			<div key='connect_button'>
				<Button
					onClick={handleConnectClick}
					disabled={state.blockchain.loading}
					style={{ cursor: !state.blockchain.loading ? "pointer" : "wait" }}
				>
					{state.blockchain.loading ? <div style={{ margin: "0px 50px", width: "0", height: "0" }} className='loader' /> : "Connect"}
				</Button>
				<SmallText className='showMobile' style={{ marginTop: "25px" }}>
					{MESSAGES.MOBILE_VIEW()}
				</SmallText>
			</div>
		);
	}

	if (contractError) {
		if (state.contractData.errorMsg === "METAMASK_NOT_FOUND") {
			displayArray.push(
				<div key='metamask_error' className='hideOnMobile' style={{ marginTop: "25px" }}>
					<SmallText>{MESSAGES.METAMASK_ERROR()}</SmallText>
				</div>
			);
		}
	}

	//Show the error message if errorMessage is true
	if (errorMessage) {
		displayArray.push(<ErrorMessage key='error_msg' msgCode={state.blockchain.errorMsg} />);
	}

	return <div style={{ textAlign: "center" }}>{displayArray}</div>;
};

export default Action;

import React from "react";
import styled from "styled-components";

export const Button = styled.button`
	margin-top: 50px;
	padding: 10px;
	color: white;
	border: solid white 3px;
	font-size: 2rem;
	background: transparent;
	cursor: pointer;
`;

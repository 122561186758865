import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "./Heading";
import { TextOnWhite } from "./Mint";

export const Button = styled.button`
	margin-top: 50px;
	padding: 10px;
	color: white;
	border: solid white 3px;
	font-size: 2rem;
	background: var(--primary);
	cursor: pointer;
`;

const Counter = ({ LIMIT, num, setNum }) => {
	const [incrementDisabled, setIncrementDisabled] = useState(false);
	const [decrementDisabled, setDecrementDisabled] = useState(true);

	const increment = () => {
		const current = num;
		const newVal = current + 1;
		if (newVal > LIMIT) {
			setNum(LIMIT);
			setIncrementDisabled(true);
		} else {
			setIncrementDisabled(false);
		}
		setDecrementDisabled(false);
		setNum(newVal);
	};

	const decrement = () => {
		const current = num;
		const newVal = current - 1;

		if (newVal <= 1) {
			setNum(1);
			setDecrementDisabled(true);
		} else {
			setDecrementDisabled(false);
		}

		setIncrementDisabled(false);
		setNum(newVal);
	};

	return (
		<div>
			<Button className='button' onClick={decrement} style={{ padding: "10px 20px" }} disabled={decrementDisabled}>
				-
			</Button>
			<TextOnWhite style={{ margin: "0px 40px" }}>{num}</TextOnWhite>
			<Button className='button' onClick={increment} style={{ padding: "10px 20px" }} disabled={incrementDisabled}>
				+
			</Button>
		</div>
	);
};

export default Counter;

import React from "react";
import styled from "styled-components";
import ComingSoon from "../components/ComingSoon";
import Mint from "../components/Mint";
// import PublicSaleMint from "../components/PublicSaleMint";
import { validForPresale, isPublicSaleActive } from "../helper";
import { Button } from "../components/Button";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import Main from "./Main";
import TopBanner from "../assets/top_banner.jpg";
import { Text } from "../components/Heading";

// Used for wrapping a page component
export const Screen = styled.div`
	background-color: var(--primary);
	background-size: cover;
	background-position: center;
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
	display: flex;
	flex: ${({ flex }) => (flex ? flex : 0)};
	flex-direction: ${({ fd }) => (fd ? fd : "column")};
	justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
	align-items: ${({ ai }) => (ai ? ai : "flex-start")};
	width: 100%;
	background-size: cover;
	background-position: center;
`;

export const StyledLogo = styled.img`
	width: 400px;
	padding-top: 50px;
	@media (min-width: 767px) {
		width: 700px;
	}
	transition: width 0.5s;
	transition: height 0.5s;
`;

const Landing = ({ connectCallback }) => {
	const blockchain = useSelector((state) => state.blockchain);

	return (
		<Screen>
			<Container flex={1} ai={"center"} style={{ backgroundColor: "var(--primary)" }}>
				<img src={TopBanner} style={{ width: "100%" }} className='hideOnMobile' />
				<StyledLogo alt={"logo"} src={"/config/images/logo.png"} />

				<Main />
				{/* {process.env.REACT_APP_IS_ACTIVE === "active" ? (
					blockchain.account && blockchain.smartContract ? (
						!isPublicSaleActive() ? (
							validForPresale(blockchain.isWhitelisted) ? (
								<Mint />
							) : (
								<ComingSoon />
							)
						) : (
							<PublicSaleMint />
						)
					) : (
						<>
							<Button onClick={() => console.log(blockchain)}>Connect</Button>
						</>
					)
				) : (
					<ComingSoon />
				)} */}
			</Container>
			<Footer />
		</Screen>
	);
};

export default Landing;

import React from "react";
import { SmallText, Text } from "../components/Heading";

const ERROR_MESSAGES = {
	METAMASK_NOT_FOUND: "Metamask not found",
	CHANGE_NETWORK: "You must be on the Ethereum Mainnnet",
	SOMETHING_WENT_WRONG: "Something went wrong, try again later.",
};

const ErrorMessage = ({ msgCode }) => {
	return (
		<div style={{ textAlign: "center" }}>
			<SmallText className='error-text' style={{ lineHeight: "1.5" }}>
				Unable to connect to Smart Contract. <br />
				<u>{ERROR_MESSAGES[msgCode]}</u> <br />
				If issue persist, please contact{" "}
				<a style={{ textDecoration: "underline" }} className='error-text' href='https://discord.gg/hWerR6zyzQ'>
					🔧#-support
				</a>{" "}
				Channel in the Discord Server
			</SmallText>
		</div>
	);
};

export default ErrorMessage;

const initialState = {
	loading: false,
    isActive: null,
    isPresaleActive: false,
    isPublicSaleActive: false,
    totalSupplyLeft: 0,
    presaleCostSet: '0',
    publicSaleCostSet: '0',
	maxSupplySet: 0,

    contractLoadedSuccess: false,
	errorMsg: "",
	maxMintAmountPerTxSet: 0,
	isRevealed: false
}

const contractReducer = (state = initialState, action) => {
	switch (action.type) {
	  case "CONTRACT_REQUESTED":
		return {
		  ...initialState,
		  loading: true,
		};
	  case "CONTRACT_SUCCESS":
		return {
			loading: false,
			isActive: !action.payload.isPaused,
			isPresaleActive: action.payload.isPresaleActive,
			isPublicSaleActive: action.payload.isPublicSaleActive,
			presaleCostSet: action.payload.preSaleCostSet,
			publicSaleCostSet: action.payload.publicSaleCostSet,
			totalSupplyLeft: action.payload.totalSupplyMinted,
			maxSupplySet: action.payload.maxSupplySet,
			contractLoadedSuccess: action.payload.contractLoadedSuccess,
			maxMintAmountPerTxSet: action.payload.maxMintAmountPerTxSet,
			isRevealed: action.payload.isRevealed
		};
	  case "CONTRACT_FAIL":
		return {
		  ...state,
		  loading: false,
		  errorMsg: action.payload,
		};
	  default:
		return state;
	}
  };
  
  export default contractReducer;
  
import React from "react";
import "../assets/styles/progressBar.css";

const ProgressBar = ({ total, sold }) => {
	const display = `${sold}/${total} Minted`;
	return (
		<div style={{ width: "85%", margin: " 40px auto" }}>
			<progress value={sold} max={total} data-label={display} />
		</div>
	);
};

export default ProgressBar;

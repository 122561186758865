import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Counter from "./Counter";
import CONFIG from "../config/config.json";
import "../assets/styles/mintBox.css";
import { Text, Heading } from "./Heading";
import ProgressBar from "./ProgressBar";
import Web3 from "web3";
import { useEffect } from "react";

export const Button = styled.button`
	margin-top: 50px;
	font-size: 2rem;
`;

const Error = () => {
	//Also possible to handle each error by error codes returned by metamask, Like if the user rejects the payment, it returns 4001 etc.
	return <TextOnWhite>Something went wrong. Please refresh the page and try again. No funds were withdrawn. </TextOnWhite>;
};

const SuccessMessage = () => {
	return (
		<div>
			<Heading style={{ color: "var(--primary)" }}>CONGRATULATIONS!</Heading>

			<TextOnWhite>NFT Minted successfully!</TextOnWhite>
			<br />
			<br />
			<TextOnWhite>
				Head over to{" "}
				<a href={CONFIG.MARKETPLACE_LINK} style={{ color: "var(--secondary)" }}>
					Opensea
				</a>{" "}
				or your Metamask wallet to checkout your personal pop art sidekick!
			</TextOnWhite>
		</div>
	);
};

const LoadingText = () => {
	const [show, setShow] = useState(false);
	const [takingLong, setTakingLong] = useState(false);
	useEffect(() => {
		setTimeout(() => setShow(true), 3000);
		setTimeout(() => setTakingLong(true), 20000);
	}, []);
	return (
		<div style={{ textAlign: "center", margin: "auto" }}>
			<div style={{ margin: "30px auto", width: "0", height: "0" }} className='loader' />
			<Heading style={{ color: "#c9c9c9" }} id='loadingText' data-text='Minting Your Personal HYPEE...'>
				Minting Your Personal HYPEE...
			</Heading>
			<TextOnWhite>{show && "Checkout Metamask extension."}</TextOnWhite> <br />
			<br />
			<TextOnWhite>{takingLong && "Taking longer than usual..."}</TextOnWhite>
		</div>
	);
};

export const TextOnWhite = ({ children, style }) => {
	return <Text style={{ color: "var(--primary)", fontSize: "1.5rem", ...style }}>{children}</Text>;
};

const mintingPriceMessage = (weiAmount) => {
	return `Mint Price: ${Web3.utils.fromWei(weiAmount, "ether")} ETH per HYPEE`;
};

const totalPriceMsg = (value, weiAmount) => {
	const eth = parseFloat((value * Web3.utils.fromWei(weiAmount, "ether")).toFixed(5));
	return `Total:  ${eth} ETH`;
};

const Mint = ({ presaleActive }) => {
	const { blockchain, contractData } = useSelector((state) => state);

	const [value, setValue] = useState(1);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const weiAmount = presaleActive ? contractData.presaleCostSet : contractData.publicSaleCostSet;

	const [left, setLeft] = useState(Number(contractData.totalSupplyLeft));

	const mint = () => {
		setLoading(true);
		let cost = presaleActive ? contractData.presaleCostSet : contractData.publicSaleCostSet;
		let gasLimit = CONFIG.GAS_LIMIT;
		let totalCostWei = String(cost * value);
		let totalGasLimit = String(gasLimit * value);
		console.log("Cost: ", totalCostWei);
		console.log("Gas limit: ", totalGasLimit);
		console.log(`Minting your ${CONFIG.NFT_NAME}...`);

		blockchain.smartContract.methods
			.mint(value)
			.send({
				gasLimit: String(totalGasLimit),
				to: CONFIG.CONTRACT_ADDRESS,
				from: blockchain.account,
				value: totalCostWei,
			})
			.once("error", (err) => {
				console.log(err);
				console.log("Sorry, something went wrong please try again later.");
				setLoading(false);
				setError(true);
			})
			.then((receipt) => {
				console.log(receipt);
				console.log(`WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`);
				setLoading(false);
				setSuccess(true);
				setLeft((prev) => prev + value);
				//dispatch(fetchData(blockchain.account));
			});
	};

	return (
		<div className='mintBox'>
			<ProgressBar total={contractData.maxSupplySet} sold={left} />
			{loading ? (
				<LoadingText />
			) : success ? (
				<SuccessMessage />
			) : error ? (
				<Error />
			) : (
				<>
					<TextOnWhite>{mintingPriceMessage(weiAmount)}</TextOnWhite> <br />
					<div style={{ marginTop: "50px" }}>
						<TextOnWhite style={{ fontSize: "1.4rem" }}>Please select the number of HYPEES you would like to mint</TextOnWhite> <br />
					</div>
					<Counter LIMIT={4} num={value} setNum={setValue} /> <br />
					<TextOnWhite>
						{totalPriceMsg(value, weiAmount)}
						<span style={{ fontSize: "0.9rem" }}> (+ Gas)</span>
					</TextOnWhite>
					<br />
					<Button className='button' onClick={mint}>
						Mint
					</Button>
				</>
			)}
		</div>
	);
};

export default Mint;

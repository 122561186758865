import React from "react";
import { Heading, Text } from "../components/Heading";

const ComingSoon = ({ presaleActive, publicSaleActive, counterVisible, isActive }) => {
	if (counterVisible) {
		//return <Heading style={{ marginBottom: "40px" }}>Welcome to the HYPEE NFT Mint, Powered by OVRT</Heading>;
	}

	const msg = () => {
		if (isActive){
			return "Public Sale Postponed. Please Check Discord for Updates";
		}
		else if (presaleActive) {
			return "Presale Now Open";
		} else if (publicSaleActive) {
			return "Now Minting";
		} else {
			return "Minting Soon";
		}
	};

	return (
		<div>
			<div style={{ marginBottom: "40px" }}>
				<Heading>4,973 PERSONAL POP ART SIDEKICKS!</Heading>
				<Text style={{ fontWeight: 300, marginBottom: "30px" }}>
					A generative NFT art project <br />
					brought to you by <span style={{ fontWeight: 500 }}>Matt Corrado + OVRT</span>
				</Text>
				<br />
			</div>
			{!counterVisible && <Text style={{ color: "#acef25" }}>{msg()}</Text>}
		</div>
	);
};

export default ComingSoon;
